import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { loadingDocSummary } from '../../constant/documentstype'
import { loadingReducer } from '../../hook/loadingReducer'

const FormComponents = ({ onUpdateFormData }) => {
  const {
    dataDocumentToLint,
    isLoadingForm,
    reducedDocuments,
    updatedDataDocumentToLint,
    activeTag,
    loadingId,
    resumeTags,
    handleCheckFormEmpty,
  } = useContext(DocsContext)
  const [formData, setFormData] = useState({ data: {} })
  const [labelToForm, setLabelToForm] = useState([])
  const [hasDataIa, setHasDataIa] = useState()
  const formRef = useRef(null)

  
  const initialState = {} // Estado inicial

  // Usando useReducer com o loadingReducer importado
  const [loadingById, dispatch] = useReducer(loadingReducer, initialState)
  useEffect(() => {
    if (activeTag) {
      const hasActiveTagToForm = resumeTags.find(
        (item) => item.tag === activeTag.part
      )
      // console.log("hasActiveTagToForm", hasActiveTagToForm)  
      // console.log("activeTag", activeTag)  
      // console.log("resumeTags", resumeTags)
      setLabelToForm(hasActiveTagToForm?.itens || [])
    }
    const currentId = `${activeTag?.part || ''}-${
      activeTag?.numberTagShowForm || 1
    }`
    setHasDataIa(currentId)
    // console.log("loadingId", loadingId)
  }, [activeTag, resumeTags, loadingId])

  useEffect(() => {
    // console.log("dataDocumentToLint", dataDocumentToLint)
    const hasDataIa = dataDocumentToLint?.find(
      (item) =>
        item.part === activeTag?.part &&
        item.numberTag === activeTag?.numberTagShowForm
    )
    // console.log("hasDataIa aqui no formcomponent", hasDataIa)

    if (hasDataIa) {
      const normalizedData = normalizeKeys(hasDataIa.data)
      if (JSON.stringify(formData.data) !== JSON.stringify(normalizedData)) {
        setFormData({ data: normalizedData })
        onUpdateFormData({ data: normalizedData })
      }
    }
  }, [dataDocumentToLint, activeTag, onUpdateFormData])

  const normalizeKeys = (data) => {
    const normalizedData = {}
    Object.keys(data).forEach((key) => {
      normalizedData[key] = data[key]
    })
    return normalizedData
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    // console.log({name, value})

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        data: {
          ...prevFormData.data,
          [name]: value,
        },
      }
      onUpdateFormData(updatedFormData)
      return updatedFormData
    })

    updatedDataDocumentToLint(name, value)
  }

  useEffect(() => {
    const checkFormEmpty = Object.values(formData.data).every(
      (value) => value === ''
    )
    handleCheckFormEmpty(checkFormEmpty)
  }, [formData, handleCheckFormEmpty])


  useEffect(() => {
    // console.log('ta labelToForm?', labelToForm, 'quem esta no loadingId?', loadingId)
  }, [loadingId, activeTag])

  return loadingId && activeTag && loadingId[`${activeTag?.part || ''}-${activeTag?.numberTagShowForm || 1}`] === 'loading' ? (
    <S.LoadingList>
      {loadingDocSummary.map((item, idx) => (
        <S.LoadingListItem key={idx}>
          {item}
          <label></label>
          <p></p>
        </S.LoadingListItem>
      ))}
    </S.LoadingList>
  ) : (
    <S.Form ref={formRef}>
      {labelToForm.map((doc, index) => (
        <S.WrapperInput key={index}>
          <S.Label>{doc.label}</S.Label>
          <S.Input
            type="text"
            name={doc.key}
            disabled={
              activeTag?.tagsValidated?.find(
                (tag) => tag.numberTag === activeTag?.numberTagShowForm
              )?.tagValidated
            }
            value={formData.data[doc.key] || ''}
            onChange={handleInputChange}
            style={{
              cursor: activeTag?.tagsValidated?.find(
                (tag) => tag.numberTag === activeTag?.numberTagShowForm
              )?.tagValidated
                ? 'not-allowed'
                : 'auto',
            }}
          />
        </S.WrapperInput>
      ))}
    </S.Form>
  );
  
}

export default FormComponents
